import Fuse from 'fuse.js'
import { useState, useMemo } from 'react'
import type { DataFuzzySearch, OptionsFuzzySearch } from './FuzzySearch.interface'

interface UseFuzzySearchResult<T> {
  searchResults: DataFuzzySearch<T>
  fuzzySearchQuery: (query: string) => void
}
// Function to remove diacritics
function removeDiacritics(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const useFuzzySearch = <T>(
  dataFuzzySearch: DataFuzzySearch<T>,
  optionsFuzzySearch: OptionsFuzzySearch<T>,
): UseFuzzySearchResult<T> => {
  const [searchResults, setSearchResults] = useState<DataFuzzySearch<T>>([])

  // Create a memoized Fuse instance with custom options
  const fuse = useMemo(() => {
    const enhancedOptions: Fuse.IFuseOptions<T> = {
      ...optionsFuzzySearch,
      getFn: (obj: T, path: string | string[]) => {
        const value = Fuse.config.getFn(obj, path)
        if (typeof value === 'string') {
          return removeDiacritics(value.toLowerCase())
        }
        return value
      },
    }
    return new Fuse(dataFuzzySearch, enhancedOptions)
  }, [dataFuzzySearch, optionsFuzzySearch])

  const fuzzySearchQuery = (query: string) => {
    const normalizedQuery = removeDiacritics(query.toLowerCase())
    const results = fuse.search(normalizedQuery)
    setSearchResults(results.map((result) => result.item))
  }

  return {
    searchResults,
    fuzzySearchQuery,
  }
}
